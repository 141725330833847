import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const IndexServicesPage = () => (
  <Layout>
    <Seo title="Home" description="Description Here" />
    <div className="siteWidthWithMargin">
        <h1>Heading One</h1>
        <h2>Heading Two</h2>
        <h3>Heading Three</h3>
        <h4>Heading Four</h4>
        <h5>Heading Five</h5>
        <h6>Heading Six</h6>
        <p>I'm baby blog biodiesel occupy distillery stumptown enamel pin hashtag pinterest letterpress selvage hexagon you probably haven't heard of them listicle pork belly fingerstache. Keytar roof party crucifix bushwick tote bag kinfolk. Cardigan glossier narwhal forage fam tofu chartreuse pork belly readymade helvetica dreamcatcher deep v blog. Heirloom adaptogen blog DIY vaporware paleo 8-bit meh twee chillwave. Wolf 90's gastropub, banh mi +1 food truck typewriter tote bag everyday carry af brooklyn kitsch before they sold out schlitz.</p>
        <p>Dummy text? More like dummy thicc text, amirite?</p>
    </div>
  </Layout>
)

export default IndexServicesPage
